<template>
  <div class="grid-container pad-top--xl">
    <div class="grid-x grid-padding-x">
      <div v-if="hashVerified === true" class="cell small-12 medium-4 medium-offset-4">
        <h4 class="pad-top">Reset password</h4>
        <form>
          <label>Password</label>
          <input
            name="password"
            type="password"
            v-model="password"
          >
          <label>Repeat password</label>
          <input
            name="password"
            type="password"
            v-model="verifyPassword"
          >
        </form>
        <button @click="resetPassword" class="button">Submit</button>
      </div>
      <div v-else class="cell small-12 medium-4 medium-offset-4">
        <p>Sorry that link has expired.</p>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="linkUri"
      :linkText="linkText"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/Messages/Message.vue';
import axios from '../../axios';

export default {
  name: 'ResetPassword',
  components: {
    appMessage: Message,
  },
  data() {
    return {
      hashVerified: false,
      linkUri: false,
      linkText: false,
      message: '',
      password: '',
      showMessage: false,
      verifyPassword: '',
    };
  },
  methods: {
    checkHash() {
      const postData = {};
      postData.hash = this.$route.params.hash;
      postData.userid = this.$route.params.userid;
      axios.post('/users/checkResetHash.json', postData)
        .then((response) => {
          this.showMessage = true;
          this.message = response.data.message;
          this.hashVerified = response.data.verified;
        });
    },
    resetPassword() {
      const postData = {};
      if (this.password === this.verifyPassword) {
        postData.password = this.password;
        postData.userid = this.$route.params.userid;
        axios.post('/users/resetPassword.json', postData)
          .then((response) => {
            this.showMessage = true;
            this.message = response.data.message;
            this.linkUri = '/';
            this.linkText = 'Login';
          });
      } else {
        this.showMessage = true;
        this.message = 'Sorry, those passwords do not match. Please re-enter.';
      }
    },
  },
  mounted() {
    this.checkHash();
  },
};
</script>
